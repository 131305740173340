import "../css/style/module/top.scss";

(function (window, document, $) {
    'use strict';
   //sticky hide
   
    //画面固定のcvボタンの表示非表示切り替え (交差API
    // .js-intersection_flagが表示されているときは固定cvボタンを非表示
    const sectionDocument = document.querySelectorAll(".js-intersection_flag");
    //.js-intersection_flagが1px、ビューポートの中にはいったら発火
    const sectionDocumentOptions = {
        root: null, // ビューポートはウィンドウ全体
        rootMargin: "0px 0px 0px 0px",
        threshold: 0 // 1ピクセルでも表示されるとコールバックが実行
    };

    function IntersectFunction(elements) {
        // 交差監視をしたもののなかで、isIntersectingがtrueになったとき
        elements.forEach(element => {
            if (element.isIntersecting) {
                $("body").addClass("js-intersection_flag--active");
            } else {
                $("body").removeClass("js-intersection_flag--active");
            }
        })
    }
    const observer = new IntersectionObserver(IntersectFunction, sectionDocumentOptions);
    sectionDocument.forEach(item => {
        observer.observe(item);
    });


    //Swiper
    let secCaseSwiper = null;
    const mediaQuery = window.matchMedia('(max-width: 1199px)');

    const checkBreakpoint = (e) => {
        if (e.matches) {
            initSwiper();
        } else if (secCaseSwiper) {
            secCaseSwiper.destroy(false, true);
        }
    }

    const initSwiper = () => {
        secCaseSwiper = new Swiper('.js-slider .swiper', {
            slidesPerView: 'auto',
            // slidesPerView: 2,
            spaceBetween: 32,
            centeredSlides: true,
            grabCursor: true,
            // loop: true,
            // loopAdditionalSlides: 1,
            // pagination: {
            //     el: '.js-slider .swiper-pagination',
            //     clickable: true,
            // },
            navigation: {
                nextEl: '.js-slider .swiper-button-next',
                prevEl: '.js-slider .swiper-button-prev',
            },

        });
    };

    mediaQuery.addEventListener('change', checkBreakpoint);
    checkBreakpoint(mediaQuery);

})(window, document, $);
